const LayersIcon = ({ style, width = 24 }: { style?: any; width?: number }) => (
  <svg
    fill="none"
    height={width}
    style={style}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 10.75L5.75 7.75V7.5L12 4.5L18.25 7.5L18.25 7.75L12 10.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M18.25 11.75L18.596 12.4154L19 12.2053L19 11.75L18.25 11.75ZM5.75 11.75H5V12.2053L5.40399 12.4154L5.75 11.75ZM12 15L11.654 15.6654L12 15.8453L12.346 15.6654L12 15ZM5.40399 12.4154L11.654 15.6654L12.346 14.3346L6.09601 11.0846L5.40399 12.4154ZM12.346 15.6654L18.596 12.4154L17.904 11.0846L11.654 14.3346L12.346 15.6654ZM19 11.75L19 11L17.5 11L17.5 11.75L19 11.75ZM5 11V11.75H6.5V11H5Z"
      fill="currentColor"
    />
    <path
      d="M18.25 15.75L18.596 16.4154L19 16.2053V15.75H18.25ZM5.75 15.75H5V16.2053L5.40399 16.4154L5.75 15.75ZM12 19L11.654 19.6654L12 19.8453L12.346 19.6654L12 19ZM5.40399 16.4154L11.654 19.6654L12.346 18.3346L6.09601 15.0846L5.40399 16.4154ZM12.346 19.6654L18.596 16.4154L17.904 15.0846L11.654 18.3346L12.346 19.6654ZM19 15.75V15H17.5V15.75H19ZM5 15V15.75H6.5V15H5Z"
      fill="currentColor"
    />
  </svg>
);

export default LayersIcon;
