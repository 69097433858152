const DiscordIcon = ({ width = 24 }: { width?: number }) => (
  <svg
    fill="none"
    height={width}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4701 6.16052C17.2803 5.62337 16.0045 5.22761 14.6706 5.00095C14.6463 4.99658 14.622 5.00751 14.6095 5.02937C14.4454 5.31653 14.2636 5.69115 14.1364 5.98559C12.7016 5.77424 11.2742 5.77424 9.86888 5.98559C9.74158 5.6846 9.55323 5.31653 9.38842 5.02937C9.3759 5.00824 9.35163 4.99731 9.32734 5.00095C7.99412 5.22689 6.71829 5.62264 5.52781 6.16052C5.5175 6.1649 5.50867 6.17219 5.5028 6.18166C3.08283 9.73909 2.4199 13.2091 2.74511 16.636C2.74658 16.6528 2.75615 16.6688 2.76939 16.679C4.36602 17.8328 5.91263 18.5332 7.43052 18.9974C7.45481 19.0047 7.48055 18.996 7.49601 18.9763C7.85507 18.4938 8.17513 17.9851 8.44956 17.4501C8.46576 17.4188 8.4503 17.3816 8.4172 17.3692C7.90952 17.1797 7.4261 16.9487 6.96109 16.6863C6.92431 16.6652 6.92137 16.6134 6.9552 16.5887C7.05306 16.5165 7.15094 16.4414 7.24438 16.3656C7.26128 16.3518 7.28484 16.3489 7.30472 16.3576C10.3596 17.73 13.6669 17.73 16.6858 16.3576C16.7057 16.3481 16.7293 16.3511 16.7469 16.3649C16.8404 16.4407 16.9382 16.5165 17.0368 16.5887C17.0706 16.6134 17.0684 16.6652 17.0317 16.6863C16.5666 16.9538 16.0832 17.1797 15.5748 17.3685C15.5417 17.3809 15.527 17.4188 15.5432 17.4501C15.8235 17.9844 16.1436 18.4931 16.496 18.9756C16.5107 18.996 16.5372 19.0047 16.5615 18.9974C18.0867 18.5332 19.6333 17.8328 21.23 16.679C21.244 16.6688 21.2528 16.6535 21.2543 16.6368C21.6435 12.6748 20.6023 9.23329 18.4944 6.18238C18.4892 6.17219 18.4804 6.1649 18.4701 6.16052ZM8.90576 14.5494C7.98602 14.5494 7.22818 13.7185 7.22818 12.6982C7.22818 11.6778 7.97133 10.8469 8.90576 10.8469C9.84754 10.8469 10.598 11.6851 10.5833 12.6982C10.5833 13.7185 9.84017 14.5494 8.90576 14.5494ZM15.1083 14.5494C14.1886 14.5494 13.4308 13.7185 13.4308 12.6982C13.4308 11.6778 14.1739 10.8469 15.1083 10.8469C16.0501 10.8469 16.8006 11.6851 16.7859 12.6982C16.7859 13.7185 16.0501 14.5494 15.1083 14.5494Z"
      fill="currentColor"
    />
  </svg>
);

export default DiscordIcon;
