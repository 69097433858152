const GlobeIcon = ({ style, width = 24 }: { style?: any; width?: number }) => (
  <svg
    fill="none"
    height={width}
    style={style}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M14.5 12C14.5 13.9214 14.1494 15.6248 13.6137 16.8197C13.3455 17.418 13.0456 17.8562 12.7503 18.1348C12.4591 18.4096 12.2069 18.5 12 18.5V20C12.6906 20 13.2934 19.6847 13.7798 19.2258C14.2622 18.7706 14.6626 18.147 14.9825 17.4333C15.6231 16.0042 16 14.0826 16 12H14.5ZM12 18.5C11.7931 18.5 11.5409 18.4096 11.2497 18.1348C10.9544 17.8562 10.6545 17.418 10.3863 16.8197C9.85063 15.6248 9.5 13.9214 9.5 12H8C8 14.0826 8.3769 16.0042 9.01752 17.4333C9.33745 18.147 9.73784 18.7706 10.2202 19.2258C10.7066 19.6847 11.3094 20 12 20V18.5ZM9.5 12C9.5 10.0786 9.85063 8.37518 10.3863 7.18027C10.6545 6.58195 10.9544 6.14382 11.2497 5.86525C11.5409 5.59044 11.7931 5.5 12 5.5V4C11.3094 4 10.7066 4.3153 10.2202 4.77423C9.73784 5.2294 9.33745 5.85301 9.01752 6.56668C8.3769 7.99575 8 9.91738 8 12H9.5ZM12 5.5C12.2069 5.5 12.4591 5.59044 12.7503 5.86525C13.0456 6.14382 13.3455 6.58195 13.6137 7.18027C14.1494 8.37518 14.5 10.0786 14.5 12H16C16 9.91738 15.6231 7.99575 14.9825 6.56668C14.6626 5.85301 14.2622 5.2294 13.7798 4.77423C13.2934 4.3153 12.6906 4 12 4V5.5Z"
      fill="currentColor"
    />
    <path d="M4.75 12.75H19.25V11.25H4.75V12.75Z" fill="currentColor" />
  </svg>
);

export default GlobeIcon;
