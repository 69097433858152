const GithubIcon = ({ style, width = 24 }: { style?: any; width?: number }) => (
  <svg
    height={width}
    style={style}
    width={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2.24609C9.6255 2.24619 7.32849 3.09121 5.51999 4.62994C3.71149 6.16867 2.50953 8.30071 2.12916 10.6446C1.74879 12.9884 2.21485 15.3912 3.44393 17.4228C4.67301 19.4545 6.58491 20.9826 8.83755 21.7336C9.33755 21.8211 9.52505 21.5211 9.52505 21.2586C9.52505 21.0211 9.51254 20.2336 9.51254 19.3961C7.00003 19.8586 6.35003 18.7836 6.15003 18.2211C5.9281 17.674 5.5763 17.1892 5.12503 16.8086C4.77503 16.6211 4.27503 16.1586 5.11252 16.1461C5.4323 16.1808 5.73901 16.2921 6.00666 16.4705C6.2743 16.6489 6.49499 16.8892 6.65003 17.1711C6.7868 17.4168 6.97071 17.6331 7.19122 17.8076C7.41173 17.9821 7.6645 18.1114 7.93506 18.188C8.20562 18.2646 8.48864 18.2871 8.76791 18.2542C9.04717 18.2213 9.3172 18.1336 9.56251 17.9961C9.6058 17.4877 9.83237 17.0124 10.2 16.6586C7.97503 16.4086 5.65003 15.5461 5.65003 11.7211C5.63597 10.7273 6.00271 9.7657 6.67503 9.03362C6.36931 8.16984 6.40508 7.22191 6.77503 6.38362C6.77503 6.38362 7.6125 6.12111 9.52503 7.40862C11.1613 6.9586 12.8887 6.9586 14.525 7.40862C16.4375 6.10862 17.275 6.38362 17.275 6.38362C17.645 7.2219 17.6808 8.16985 17.375 9.03362C18.0494 9.76445 18.4164 10.7269 18.4 11.7211C18.4 15.5586 16.0625 16.4086 13.8375 16.6586C14.0762 16.9005 14.26 17.1909 14.3764 17.5101C14.4929 17.8293 14.5393 18.1699 14.5125 18.5086C14.5125 19.8461 14.5 20.9211 14.5 21.2586C14.5 21.5211 14.6875 21.8336 15.1875 21.7336C17.4362 20.9765 19.3426 19.4449 20.5664 17.4121C21.7903 15.3794 22.2519 12.9779 21.8689 10.6363C21.4859 8.29474 20.2832 6.16547 18.4755 4.6286C16.6678 3.09174 14.3727 2.24733 12 2.24609Z"
      fill="currentColor"
    />
  </svg>
);

export default GithubIcon;
