const LinkIcon = ({ style, width = 24 }: { style?: React.CSSProperties; width?: number }) => (
  <svg
    fill="none"
    height={width}
    style={style}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7375 6.37333L14.2678 6.90366H14.2678L13.7375 6.37333ZM11.2626 8.8482L10.7323 8.31787H10.7323L11.2626 8.8482ZM17.273 11.6766L18.1569 10.7927L17.0962 9.73208L16.2124 10.616L17.273 11.6766ZM13.2072 5.843L10.7323 8.31787L11.7929 9.37853L14.2678 6.90366L13.2072 5.843ZM10.7323 8.31787C9.36544 9.68471 9.36544 11.9008 10.7323 13.2676L11.7929 12.207C11.0119 11.4259 11.0119 10.1596 11.7929 9.37853L10.7323 8.31787ZM18.1569 5.843C16.7901 4.47616 14.574 4.47616 13.2072 5.843L14.2678 6.90366C15.0489 6.12261 16.3152 6.12261 17.0962 6.90366L18.1569 5.843ZM18.1569 10.7927C19.5237 9.42591 19.5237 7.20983 18.1569 5.843L17.0962 6.90366C17.8773 7.68471 17.8773 8.95104 17.0962 9.73208L18.1569 10.7927Z"
      fill="currentColor"
    />
    <path
      d="M13.7375 14.1515L14.2679 14.6819L13.7375 14.1515ZM11.2627 16.6264L10.7323 16.0961L11.2627 16.6264ZM7.37357 12.7373L7.9039 13.2677V13.2677L7.37357 12.7373ZM13.2072 13.6212L10.7323 16.0961L11.793 17.1567L14.2679 14.6819L13.2072 13.6212ZM7.9039 13.2677L8.96456 12.207L7.9039 11.1463L6.84324 12.207L7.9039 13.2677ZM7.9039 16.0961C7.12285 15.315 7.12285 14.0487 7.9039 13.2677L6.84324 12.207C5.47641 13.5738 5.47641 15.7899 6.84324 17.1567L7.9039 16.0961ZM10.7323 16.0961C9.95128 16.8771 8.68495 16.8771 7.9039 16.0961L6.84324 17.1567C8.21008 18.5236 10.4262 18.5236 11.793 17.1567L10.7323 16.0961ZM13.2072 10.7928C13.9883 11.5738 13.9883 12.8402 13.2072 13.6212L14.2679 14.6819C15.6347 13.315 15.6347 11.099 14.2679 9.73212L13.2072 10.7928Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkIcon;
