const TelegramIcon = ({ style, width = 24 }: { style?: any; width?: number }) => (
  <svg
    height={width}
    style={style}
    width={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9941 2C10.0163 2 8.08293 2.58649 6.43844 3.6853C4.79395 4.78412 3.51223 6.3459 2.75535 8.17317C1.99847 10.0004 1.80044 12.0111 2.18629 13.9509C2.57214 15.8907 3.52455 17.6725 4.92308 19.0711C6.3216 20.4696 8.10343 21.422 10.0432 21.8079C11.9831 22.1937 13.9937 21.9957 15.821 21.2388C17.6482 20.4819 19.21 19.2002 20.3088 17.5557C21.4077 15.9112 21.9941 13.9778 21.9941 12C21.9943 10.6868 21.7357 9.38634 21.2332 8.17304C20.7307 6.95973 19.9941 5.8573 19.0655 4.92869C18.1368 4.00008 17.0344 3.26349 15.8211 2.76098C14.6078 2.25847 13.3074 1.99989 11.9941 2ZM15.1737 17.1525C15.1362 17.2458 15.0793 17.33 15.0066 17.3996C14.934 17.4691 14.8473 17.5223 14.7524 17.5556C14.6575 17.5889 14.5566 17.6015 14.4565 17.5926C14.3563 17.5837 14.2592 17.5535 14.1717 17.504L11.457 15.3946L9.71466 17.002C9.67424 17.0319 9.62695 17.0511 9.57714 17.0579C9.52734 17.0647 9.47662 17.0589 9.42967 17.0409L9.76366 14.0525L9.77436 14.061L9.78118 14.002C9.78118 14.002 14.6662 9.55445 14.8652 9.36496C15.0667 9.17596 15.0002 9.13496 15.0002 9.13496C15.0117 8.90443 14.6387 9.13496 14.6387 9.13496L8.16614 13.299L5.47066 12.381C5.47066 12.381 5.05666 12.2325 5.01766 11.906C4.97666 11.582 5.48415 11.406 5.48415 11.406L16.2011 7.14849C16.2011 7.14849 17.0821 6.75597 17.0821 7.406L15.1737 17.1525Z"
      fill="currentColor"
    />
  </svg>
);

export default TelegramIcon;
