const Cube3DIcon = ({ style, width = 24 }: { style?: any; width?: number }) => (
  <svg
    fill="none"
    height={width}
    style={style}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.25 8H20V7.55722L19.6123 7.34332L19.25 8ZM12 4L12.3623 3.34332L12 3.14342L11.6377 3.34332L12 4ZM4.75 8L4.38769 7.34332L4 7.55722V8H4.75ZM4.75 16H4V16.4428L4.38769 16.6567L4.75 16ZM12 20L11.6377 20.6567L12 20.8566L12.3623 20.6567L12 20ZM19.25 16L19.6123 16.6567L20 16.4428V16H19.25ZM11.6377 3.34332L4.38769 7.34332L5.11231 8.65668L12.3623 4.65668L11.6377 3.34332ZM4 8V16H5.5V8H4ZM4.38769 16.6567L11.6377 20.6567L12.3623 19.3433L5.11231 15.3433L4.38769 16.6567ZM12.3623 20.6567L19.6123 16.6567L18.8877 15.3433L11.6377 19.3433L12.3623 20.6567ZM20 16V8H18.5V16H20ZM19.6123 7.34332L12.3623 3.34332L11.6377 4.65668L18.8877 8.65668L19.6123 7.34332Z"
      fill="currentColor"
    />
    <path
      d="M12.3623 12.6567L19.6123 8.65668L18.8877 7.34332L11.6377 11.3433L12.3623 12.6567ZM12.3623 11.3433L5.11231 7.34332L4.38769 8.65668L11.6377 12.6567L12.3623 11.3433ZM11.25 12V20H12.75V12H11.25Z"
      fill="currentColor"
    />
  </svg>
);

export default Cube3DIcon;
